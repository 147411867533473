import { Plugin, Responder } from "@fysiweb/elm-ports";

export default (new Plugin(
  "notification",
  (data: any, respond: Responder) => {
    switch (data.method) {
      case "request-permission": {
        if (!("Notification" in window)) {
          throw new Error("notifications are not supported");
        }
        (new Promise((resolve, reject) => {
          if (Notification.permission === "granted") {
            resolve("granted");
          } else {
            Notification.requestPermission().then((permission) => {
              respond(permission, true);
            })
            .catch((permission) => {
              throw new Error(`permission not granted: ${permission}`);
            })
          }
        }))
        break;
      }
      case "new": {
        if (!("Notification" in window)) {
          throw new Error("notifications are not supported");
        }
        (new Promise((resolve, reject) => {
          if (Notification.permission === "granted") {
            resolve("granted");
          } else {
            Notification.requestPermission().then((permission) => {
              resolve(permission);
            })
          }
        }))
          .then((permission) => {
            const notification = new Notification(data.title, data.options);
            notification.addEventListener("click", (event) => respond(event, false));
            notification.addEventListener("close", (event) => respond(event, true));
            notification.addEventListener("error", (event) => respond(event, true));
            notification.addEventListener("notificationclick", (event) => respond(event, false));
            notification.addEventListener("show", (event) => respond(event, false));
          })
          .catch((permission) => {
            throw new Error(`permission not granted: ${permission}`)
          });
        break;
      }
      default: {
        throw new Error(`unknown method: ${data.method}`);
      }
    }
  })
);
