import console_ from "./console";
import { Handler, Plugin, Responder } from "./plugin";

class Ports {
  app: any;
  plugins: Map<String, Plugin>;

  constructor(app: any, plugins: Plugin[] = []) {
    this.app = app;
    this.plugins = new Map();
    this.app.ports.portsRequest.subscribe(this.handle.bind(this));
    this.register(console_);
    for (const plugin of plugins) {
      this.register(plugin);
    }
  }

  private handle(payload: any) {
    const plugin = this.plugins.get(payload.name);
    if (plugin) {
      try {
        const data = plugin.handle(
          payload.data,
          (data: any, remove: boolean) => {
            this.app.ports.portsResponse.send({
              data,
              id: payload.id,
              name: payload.name,
              remove,
            });
          }
        );
      } catch (error) {
        let message = error instanceof Error ? error.message : `${error}`;
        console.error(`elm-ports: plugin ${name} produced an error: ${message}`, payload);
      }
    } else {
      console.error(`elm-ports: unhandled payload, did you forget to register plugin ${name}`, payload);
    }
  }

  private register(plugin: Plugin) {
    this.plugins.set(plugin.name, plugin);
  }
}

export {
  Handler,
  Plugin,
  Ports,
  Responder,
}
