import * as widgets from "instantsearch.js/es/widgets";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import instantsearch from "instantsearch.js";

customElements.define("elm-instantsearch-hit",
  class InstantSearchHit extends HTMLElement {
    private handleClick_: any;
    private initialized: Boolean = false;

    constructor() {
      super();
      this.attachShadow({ mode: "open" });
    }

    connectedCallback() {
      this.init();
    }

    disconnectedCallback() {
      this.deinitEvents();
    }

    init() {
      this.initDom();
      this.initEvents();
      this.initialized = true;
    }

    initDom() {
      const template = document.createElement("template");
      template.innerHTML = `
        <slot name="title"></slot>
        <slot name="description"></slot>
      `;
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    initEvents() {
      this.handleClick_ = this.handleClick.bind(this);
      this.addEventListener("click", this.handleClick_);
    }

    deinitEvents() {
      this.removeEventListener("click", this.handleClick_);
    }

    handleClick(event: Event) {
      this.dispatchEvent(new CustomEvent("open", {
        bubbles: true,
        detail: {
          slug: this.getAttribute("slug")
        }
      }));
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
  }
);

customElements.define("elm-instantsearch",
  class InstantSearch extends HTMLElement {
    private hits: HTMLElement;
    private initialized: Boolean = false;
    private instantsearch: any;
    private queryHook_: any;
    private query_: String = "";
    private searchBox: HTMLElement;
    public host?: string;
    public publicKey?: string;

    get query() {
      return this.query_;
    }

    set query(query: String) {
      const oldQuery = this.query_;
      this.query_ = query;
      if (oldQuery !== query) this.search();
    }

    connectedCallback() {
      this.init();
      this.search();
    }

    init() {
      this.initDom();
      this.initEvents();
      this.initInstantsearch();
      this.initialized = true;
    }

    initDom() {
      this.hits = document.createElement("div");
      this.hits.classList.add("search__hits-wrapper");
      this.searchBox = document.createElement("div");
      this.searchBox.classList.add("search__search-box-wrapper");
      this.append(this.searchBox);
      this.append(this.hits);
    }

    initEvents() {
      this.queryHook_ = this.queryHook.bind(this);
    }

    initInstantsearch() {
      this.instantsearch = instantsearch({
        indexName: "page",
        searchClient: instantMeiliSearch(this.host, this.publicKey) as any
      });
      this.instantsearch.addWidgets([
        widgets.searchBox({
          autofocus: true,
          container: this.searchBox,
          cssClasses: {
            form: "search__search-box__form",
            input: "search__search-box__input",
            loadingIndicator: "search__search-box__loading-indicator material-icons",
            reset: "search__search-box__reset material-icons",
            root: "search__search-box",
            submit: "search__search-box__submit material-icons",
          },
          queryHook: this.queryHook_,
          templates: {
            loadingIndicator({ cssClasses }, { html }) {
              return html`refresh`;
            },
            reset({ cssClasses }, { html }) {
              return html`close`;
            },
            submit({ cssClasses }, { html }) {
              return html`search`;
            },
          },
        }),
        widgets.configure({
          hitsPerPage: 8,
        }),
        widgets.hits({
          container: this.hits,
          cssClasses: {
            emptyRoot: "search__hits search__hits--empty",
            item: "search__hit-wrapper",
            list: "search__hits__wrapper",
            root: "search__hits",
          },
          templates: {
            empty(results, { html }) {
              return html`No results for <q>${results.query}</q>`;
            },
            item(hit, { html, components }) {
              return html`
                <a href="${hit.slug}" is="elm-instantsearch-hit" class="search__hit">
                  <div slot="title" class="search__hit__title">
                    ${components.Highlight({ attribute: "title", hit })}
                  </div>
                  <div slot="description" class="search__hit__description">
                    ${components.Highlight({ attribute: "description", hit })}
                  </div>
                </a>
              `;
            },
          }
        })
      ]);
      this.instantsearch.start();
    }

    queryHook(query: String) {
      this.dispatchEvent(new CustomEvent("query", { detail: { query } }));
    }

    search() {
      if (!this.initialized) return;
      this.instantsearch.helper.setQuery(this.query_).search(this.query_);
    }
  }
);
