// @ts-ignore
import { Elm } from "./elm-optimized.js";
import { Ports, Plugin } from "@fysiweb/elm-ports";
import PortsAuthentication, * as authentication from "@fysiweb/elm-ports-authentication/src/msal";
import PortsColorScheme, * as colorScheme from "./ports/color-scheme";
import PortsDiscoverSwissCheckout from "@fysiweb/elm-discover-swiss-checkout";
import PortsGeolocation from "@fysiweb/elm-ports-geolocation";
import PortsNotification from "@fysiweb/elm-ports-notification";
import PortsSeo from "./ports/seo";
import "strapi-plugin-design/src";
import "material-components-web-elm/dist/material-components-web-elm.js";

(async () => {
  const devSearch = {
    host: "https://search-dev.ol-weltcup.app",
    publicKey: "2d69ad6eb914a3b9cdd01a52ecf3db797fb179b15feb6f44f53a1b9ab32b949c",
  };
  const search = {
    ["https://dev.ol-weltcup.app"]: devSearch,
    ["https://ol-weltcup.app"]: {
      host: "https://search-master.ol-weltcup.app",
      publicKey: "52cbb8e165ea5dc7981c727974c4bf1214c3c5db2da9f864c6d8b85df31e7cdc",
    },
  }[window.origin] || devSearch;
  const cmsUrl = {
    ["https://dev.ol-weltcup.app"]: "https://cms-dev.ol-weltcup.app",
    ["https://test.ol-weltcup.app"]: "https://cms-test.ol-weltcup.app",
    ["https://preview.ol-weltcup.app"]: "https://cms-preview.ol-weltcup.app",
    ["https://ol-weltcup.app"]: "https://cms-master.ol-weltcup.app",
  }[window.origin] || "http://localhost:1337";
  const production = window.origin === "https://ol-weltcup.app"
    || window.origin === "https://preview.ol-weltcup.app";
  const policy = production
    ? "B2C_1A_signup_signin"
    : "B2C_1A_signup_signin_test";
  const clientId = production
    ? "4e572f35-a105-4524-b3c8-aadb980ed447"
    : "ec742b99-33f7-41b8-8f15-928e2e223996";
  try {
    const elmApp = Elm.Main.init({
      flags: {
        authentication: await authentication.init({
          authority: `https://discoverswissb2c.b2clogin.com/discoverswissb2c.onmicrosoft.com/${policy}`,
          clientId,
          extraQueryParameters: {
            "partnerAcronym": "woc",
            "ui_locales": "de",
          },
          knownAuthorities: [ "discoverswissb2c.b2clogin.com" ],
          scopes: [
            clientId,
            "offline_access"
          ]
        }),
        cmsUrl,
        colorScheme: await colorScheme.init(),
        language: navigator.userAgent.match(/\b(googlebot|prerender)\b/i) ? "de" : navigator.language,
        production,
        screen: {
          width: window.innerWidth,
          height: window.innerHeight
        },
        search,
      }
    });

    new Ports(elmApp, [
      PortsAuthentication,
      PortsColorScheme,
      PortsDiscoverSwissCheckout,
      PortsGeolocation,
      PortsNotification,
      PortsSeo,
    ]);
  } catch (e) {
    console.error(e);
  }
})()

navigator.serviceWorker.register(
  new URL("service-worker.js", import.meta.url),
  { type: "module" }
);
