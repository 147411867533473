import { Plugin, Responder } from "@fysiweb/elm-ports";

const urls = process.env.ENVIRONMENT === "production"
  ? [
    "https://cdn.discover.swiss/webcomponents/checkout/checkout-core-0.js",
    "https://cdn.discover.swiss/webcomponents/checkout/checkout-product-selector-0.js",
    "https://cdn.discover.swiss/webcomponents/checkout/checkout-product-variant-selector-0.js",
    "https://cdn.discover.swiss/webcomponents/checkout/checkout-shopping-cart-0.js"
  ] : [
    "https://cdn-test.discover.swiss/webcomponents/checkout/checkout-core-0.js",
    "https://cdn-test.discover.swiss/webcomponents/checkout/checkout-product-selector-0.js",
    "https://cdn-test.discover.swiss/webcomponents/checkout/checkout-product-variant-selector-0.js",
    "https://cdn-test.discover.swiss/webcomponents/checkout/checkout-shopping-cart-0.js"
  ];

let loaded = false;

export default (new Plugin(
  "discover-swiss-checkout",
  async (data: string, respond: Responder) => {
    if (data === "watch") {
      window.addEventListener("dsForceAuthReq", event => respond(event.type, false));
      window.addEventListener("dsRefreshAuthTokenReq", event => respond(event.type, false));
      return;
    }
    if (data === "close") {
      window.dispatchEvent(new Event("dsSendCloseShoppingCartEvent"));
      return;
    }
    if (data === "load") {
      if (loaded) {
        respond(true, false);
        return;
      }
      const head = document.head;
      respond(loaded = (await Promise.all(urls.map(url =>
        new Promise((resolve, reject) => {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = url;
          script.onload = resolve;
          script.onerror = reject;
          head.appendChild(script);
        })
      ))).every(x => x), false);
      return;
    }
  }
));
