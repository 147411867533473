import { Plugin, Responder } from "@fysiweb/elm-ports";

export default (new Plugin(
  "color-scheme",
  (colorScheme: { default: string, mode: string }, respond: Responder) => {
    const newColorScheme = {
      ...colorScheme,
      mode: colorScheme.mode === "browser" ? "inverse-browser" : "browser",
    };
    const pattern = new RegExp(/(prefers-color-scheme: *)(light|dark)/);
    const nodes = [...document.querySelectorAll("link[rel=stylesheet]")]
      .filter((node: HTMLLinkElement) =>
        node.media && node.media.match(pattern) !== null
      );
    const switchPrefersColorScheme = (node: HTMLLinkElement) => {
      node.media = node.media.replace(
        pattern,
        (match: string, prefersColorScheme: string, colorScheme: string) => {
          return `${prefersColorScheme}${colorScheme === "light"
            ? "dark" : "light"}`;
        }
      );
    };
    // Safari does not take kindly to replacing a `<link>`'s `media` attribute, and shows an FOUC (Flash Of Unstyled Content). We therefore copy and append the new `<link>`s, but we do have to clean up the original `<link>`s, in order to conserve resources.
    nodes.forEach((originalNode: any) => {
      const clonedNode = originalNode.cloneNode();
      switchPrefersColorScheme(clonedNode);
      // XXX Note that this is NOT correct: upon loading the, say, light `<link>`, we remove the original dark `<link>`. This does not cause issues, since both resources are already pre-loaded.
      clonedNode.addEventListener('load', () => {
        originalNode.remove();
      });
      clonedNode.addEventListener('error', () => {
        // If the cloned node cannot be loaded, we fall back to switching the original node, as that at least preserves correctness.
        switchPrefersColorScheme(originalNode);
        clonedNode.remove();
      });
      originalNode.parentElement.appendChild(clonedNode);
    });
    respond(newColorScheme, true);
  }
));

const init = async () => {
  return {
    colorScheme: {
      default: matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light",
      mode: "browser",
    }
  };
};

export {
  init,
}
