/**
 * @license
 * Copyright 2016 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */

import './native-shim.es5.js';

import '@webcomponents/custom-elements';

import '@material/button/custom-element';
import '@material/card/custom-element';
import '@material/checkbox/custom-element';
import '@material/chips/chip-set/custom-element';
import '@material/chips/chip/custom-element';
import '@material/circular-progress/custom-element';
import '@material/data-table/custom-element';
import '@material/dialog/custom-element';
import '@material/drawer/custom-element';
import '@material/fab/custom-element';
import '@material/form-field/custom-element';
import '@material/icon-button/custom-element';
import '@material/image-list/custom-element';
import '@material/layout-grid/custom-element';
import '@material/linear-progress/custom-element';
import '@material/list/custom-element';
import '@material/menu/custom-element';
import '@material/radio/custom-element';
import '@material/ripple/custom-element';
import '@material/select/custom-element';
import '@material/slider/custom-element';
import '@material/snackbar/custom-element';
import '@material/switch/custom-element';
import '@material/tab-bar/custom-element';
import '@material/tab/custom-element';
import '@material/textfield/custom-element';
import '@material/top-app-bar/custom-element';
