import * as msal from "@azure/msal-browser";
import { Plugin, Responder } from "@fysiweb/elm-ports";

type Options = {
  authority: string,
  clientId: string,
  extraQueryParameters: { [key: string]: string },
  knownAuthorities: string[],
  scopes: string[],
}

class ProviderMsal {
  private msal: msal.PublicClientApplication;
  private options: Options;
  private tokenResponse?: msal.AuthenticationResult;

  constructor(options: Options) {
    this.options = options;
    this.msal = new msal.PublicClientApplication({
      auth: {
        clientId: this.options.clientId,
        authority: this.options.authority,
        knownAuthorities: this.options.knownAuthorities,
      },
      cache: {
        cacheLocation: msal.BrowserCacheLocation.LocalStorage,
      },
      system: {
        loggerOptions: {
          loggerCallback: this.loggerCallback,
        },
      },
    });
  }

  async start() {
    try {
      if (!(this.tokenResponse = await this.msal.handleRedirectPromise() || undefined)) {
        this.tokenResponse = await this.acquireToken();
      }
    } finally {
      globalInstance = this;
      return this.tokenResponse;
    }
  }

  private loggerCallback(
    level: msal.LogLevel,
    message: string,
    containsPii: Boolean
  ) {
    // note: `Pii` refers to 'personally identifiable information'
    if (process.env.NODE_ENV === "production" && containsPii) return;
    switch (level) {
      case msal.LogLevel.Error:
        console.error(message);
        break;
      case msal.LogLevel.Info:
        console.info(message);
        break;
      case msal.LogLevel.Verbose:
        console.debug(message);
        break;
      case msal.LogLevel.Warning:
        console.warn(message);
        break;
    }
  }

  private async acquireToken(): Promise<msal.AuthenticationResult | undefined> {
    const accounts = this.msal.getAllAccounts();
    if (accounts.length > 1) throw "multiple accounts";
    if (accounts.length > 0) {
      const account = accounts[0];
      try {
        this.tokenResponse = await this.msal.acquireTokenSilent({
          account,
          scopes: this.options.scopes,
        });
        return this.tokenResponse;
      } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
          this.tokenResponse = await this.msal.ssoSilent({
            scopes: this.options.scopes,
          });
          return this.tokenResponse;
        }
        else {
          throw error;
        }
      }
    }
  };

  private signIn() {
    // redirect response will be handled in `init`, and passed via flags
    this.msal.loginRedirect({
      scopes: this.options.scopes,
      // XXX we redirect to `/welcome` which is unnecessary
      redirectUri: `${window.location.origin}/welcome`,
      extraQueryParameters: this.options.extraQueryParameters,
      state: window.location.href,
    });
  }

  private signOut() {
    // redirect response will be handled in `init`, and passed via flags
    this.msal.logoutRedirect({});
  }

  private async watch(respond: Responder) {
    const loop = async () => {
      if (this.tokenResponse) {
        const { expiresOn } = this.tokenResponse;
        if (!expiresOn) throw "tokenResponse without expiresOn, aborting";
        if ((new Date(expiresOn)).getTime() - (new Date()).getTime() < 5 * 60 * 1000) {
          try {
            this.tokenResponse = await this.acquireToken();
            respond(
              {
                type: "change",
                authenticationResult: this.tokenResponse
              },
              false
            );
          } catch (error) {
            let errorMessage;
            if (error instanceof msal.InteractionRequiredAuthError) {
              errorMessage = "interactive-login-required";
            } else if (error instanceof msal.AuthError) {
              errorMessage = error.errorMessage;
            } else if (error instanceof Error) {
              errorMessage = error.message || `${error}`;
            } else {
              errorMessage = `${error}`;
            }
            respond({ type: "change", error: errorMessage }, false);
            respond({ type: "error", error: errorMessage }, false);
            if (error instanceof msal.InteractionRequiredAuthError) return;
          }
        }
      }
      setTimeout(loop, 1000);
    };
    loop();
  }

  async handle(data: any, respond: Responder) {
    switch (data.method) {
      case "sign-in": {
        await this.signIn();
        break;
      }
      case "sign-out": {
        await this.signOut();
        break;
      }
      case "watch": {
        await this.watch(respond);
        break;
      }
      default: {
        throw new Error(`unknown method: ${data.method}`);
      }
    }
  }
}

let globalInstance: ProviderMsal | undefined;

const extractUserState = () => {
  // the URL's hash parameter is the library state joined with the user state, and separated by `|`
  return (
    (new URLSearchParams(window.location.hash.replace(/^#/, "?")))
      .get("state") || ""
  ).split("|").slice(1).join("|");
}

export const init = async (options: Options) => {
  try {
    const authResult = await (new ProviderMsal(options)).start();
    // XXX by navigating back after sign in, we end up on the redirect URL which is not handled by the application. We thus navigate to the "start page" which we pass in the `state` parameter of `loginRedirect`
    const startPage = extractUserState();
    if (startPage) window.location.replace(startPage);
    return authResult;
  } catch (error) {
    let errorMessage;
    if (error instanceof msal.AuthError) {
      errorMessage = error.errorMessage;
    } else if (error instanceof Error) {
      errorMessage = error.message || `${error}`;
    } else {
      errorMessage = `${error}`;
    }
    console.error(`authentication: ${errorMessage}`);
    return null;
  }
};

export default (new Plugin(
  "authentication",
  async (data: any, respond: Responder) => {
    if (!globalInstance) throw "elm-port's plugin `PortsAuthentication` initialized before call to `PortsAuthentication.init`. This plugin assumes that you call `PortsAuthentication.init` to pass authentication data to Elm's flags, and initialize Ports afterwards.";
    await globalInstance.handle(data, respond);
  })
);
