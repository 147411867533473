import { Plugin, Responder } from "./plugin";

export default new Plugin(
  "console",
  (data: [string], respond: Responder) => {
    const [method, ...params] = data;
    if (method in console) {
      (console as any)[method](...params);
    } else {
      throw new Error(`unknown method: ${method}`);
    }
  }
);
