import { Plugin, Responder } from "@fysiweb/elm-ports";

export default (new Plugin(
  "geolocation",
  (data: any, respond: Responder) => {
    switch (data.method) {
      case "get": {
        navigator.geolocation.getCurrentPosition(
          (data) => respond(data, true),
          (error) => respond(error, true),
          data.options
        );
        break;
      }
      case "watch": {
        navigator.geolocation.watchPosition(
          (data) => respond(data, false),
          (error) => respond(error, false),
          data.options
        );
        break;
      }
      default: {
        throw new Error(`unknown method: ${data}`);
      }
    }
  })
);
