class Plugin {
  name: String;
  handle: Handler;
  constructor(name: String, handle: Handler) {
    this.name = name;
    this.handle = handle;
  }
}

type Handler = (data: any, respond: Responder) => void;

type Responder = (data: any, remove: boolean) => void;

export {
  Handler,
  Plugin,
  Responder,
}
