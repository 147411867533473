import { Plugin, Responder } from "@fysiweb/elm-ports";

type Seo = {
  description: string,
  image: [Image],
  title: string,
};

type Image = {
  alternativeText: string,
  url: string,
  size: ImageSize,
}

type ImageSize = {
  height: number,
  width: number,
}

const title = (content: string) => {
  const node = document.createElement("title");
  node.innerText = content;
  return node;
};

const meta = (name: string, content: string) => {
  const node = document.createElement("meta");
  node.setAttribute("name", name);
  node.setAttribute("content", content);
  return node;
};

export default (new Plugin(
  "seo",
  (seo: Seo) => {
    const head = document.querySelector("head");
    [
      ...head.querySelectorAll([
        "meta[name=description]",
        "meta[name='og:description']",
        "meta[name='og:image']",
        "meta[name='og:image:alt']",
        "meta[name='og:image:height']",
        "meta[name='og:image:width']",
        "meta[name='og:title']",
        "meta[name='og:type']",
        "meta[name='og:url']",
        "title",
      ].join(","))
    ].forEach(node => node.remove());
    if (seo.title)
      head.appendChild(title(seo.title));
    if (seo.description)
      head.appendChild(meta("description", seo.description));
    if (seo.title && seo.image.length > 0) {
      head.appendChild(meta("og:title", seo.title));
      head.appendChild(meta("og:type", "website"));
      seo.image.forEach(image => {
        head.appendChild(meta("og:image", image.url));
        if (image.size) {
          head.appendChild(meta("og:image:width", `${image.size.width}`));
          head.appendChild(meta("og:image:height", `${image.size.height}`));
        }
        if (image.alternativeText)
          head.appendChild(meta("og:image:alt", `${image.alternativeText}`));
      });
      head.appendChild(meta("og:url", window.location.href));
      if (seo.description)
        head.appendChild(meta("og:description", seo.description));
    }
  }
));
