class MdcDataTable extends HTMLElement {

  constructor() {
    super();
  }

  connectedCallback() {
  }

  disconnectedCallback() {
  }
};

customElements.define("mdc-data-table", MdcDataTable);
